<template>
  <el-container style="width: 100%;height: 100%;min-width: 1400px">
    <el-aside width="13.75rem" style="display: flex;flex-direction: column;height:100%">
      <!--      <el-image fit="fill"  :src="require('@/assets/logo.png')"></el-image>-->
      <div class="logo">
        <img :src="require('@/assets/logo.png')" alt="" style="width: 2rem">
        <img :src="require('@/assets/white_MAKAFIM.png')" alt="" style="margin-left:0.75rem;height: 1.625rem">
      </div>
      <el-menu
          class="el-menu-vertical-demo"
          router
          :default-active="menuIndex"
          style="flex: 1;border: 0"
          background-color="#373531"
          text-color="#fff"
          active-text-color="#FFBE58"
      >
        <template v-for="(item,index) in menuList">
          <el-submenu v-if="item.children" :index="item.path">
            <template slot="title">
              <div v-html="item.image"></div>
              <span>{{item.title}}</span>
            </template>
            <el-menu-item-group v-for="it in item.children" :index="it.path">
              <el-menu-item :index="it.path">{{it.title}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item v-else  :index="item.path">
            <img :src="item.image" alt="">
            <span slot="title">{{item.title}}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="border_bottom" style="display: flex;justify-content: space-between;align-items: center">
        <div style="font-size: 0.9rem">{{$utils.CacheLocalGet('nickname') || ''}}</div>
        <el-link class="login_out" :underline="false" @click="LoginOut">退出登录</el-link>
      </el-header>
      <el-main style="padding: 0;position: relative;background: #F5F5F5">
        <div class="app-layout-breadcrumb">
          <el-breadcrumb separator=">">
            <el-breadcrumb-item v-if="breadcrumb.length===1">
              {{breadcrumb[0].title}}
            </el-breadcrumb-item>
            <el-breadcrumb-item v-else v-for="(item,index) in breadcrumb" :to="{ path:item.path }">
              {{item.title}}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div style="margin: 0 1.5rem;overflow: auto;height:calc(100% - 4.625rem)">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view  v-if="!$route.meta.keepAlive"></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Home from '@/router/PC/ActiveRoute/index'
export default {
  name:'client',
  data() {
    return {
      menuList:[],
    };
  },
  computed:{
    breadcrumb(){
      if(!this.$route.meta.isMenu){
        return [{
          path:this.$route.path,
          title:this.$route.meta.title,
        }]
      }
      let arr= this.$route.meta.index.split('/')
      let res=[{},{
        path:this.$route.path,
        title:this.$route.meta.title,
      }]
      res[0]=this.GetRouterList(Home[0]).find(item=>item.index===arr[0])
      return res
    },
    menuIndex(){
      return this.$route.path
    },
  },
  mounted() {
    this.GetMenuList()
  },
  methods: {
    //获取侧边栏数据
    GetMenuList(){
      //侧边栏数据
      this.menuList=Home[0].children.map(item=>{
        if(item.meta.isMenu){
          return null
        }
        if(item.children && item.children.length>0){
          return {
            ...item.meta,
            path:item.path,
            children:item.children.map(it=>{
              return {
                ...it.meta,
                path:it.path,
              }
            })
          }
        }else{
          return {
            ...item.meta,
            path:item.path
          }
        }
      }).filter(item=>item!==null)
    },
    //扁平化路由
    GetRouterList(data){
      return   data.children.map(item=>{
        if(item.children){
          return this.GetRouterList(item)
        }else{
          // if(!item.meta.isMenu){
          return {
            index:item.meta.index,
            path:item.path,
            title:item.meta.title,
          }
          // }
        }
      }).flat().filter(item=>item!==undefined)
    },
    //退出登录
    LoginOut(){
      this.$confirm('确定退出系统吗?', {
        confirmButtonText: '确定',
        cancelButtonText:'取消',
        closeOnClickModal: false,
        customClass:'loginout_confirm',
      }).then(res=>{
        this.$api.post('v1/api/makafin/base_account/logout',{},res=>{
          let sessionMoney=this.$utils.CacheSessionGet('moneyloans')
          let CloseAll= this.$store.getters.GetWindowViewClose
          if(CloseAll.length){
            CloseAll.forEach(item=>{
              item.close()
            })
            this.$store.commit('setWindowViewClose', '')
          }
          this.$utils.CacheSessionClear()
          this.$utils.CacheLocalClear()
          this.$utils.cookieRemove('__ERP_IDENTITY_NUMBER__');
          this.$utils.cookieRemove('__ERP_IDENTITY_TOKEN__');
          this.$utils.cookieRemove('__ERP_IDENTITY_TOKEN__KEY__');
          this.$utils.cookieRemove('__ERP_USERNAME__');
          this.$utils.cookieRemove('__ERP_SELLER_USERNAME__');
          this.$utils.cookieRemove('__ERP_SELLER_ID__');
          this.$store.commit('setNickName','' )
          this.$router.push({path:'/home/index'})
          this.$utils.CacheSessionSet('moneyloans',sessionMoney)
        })
      }).catch(err=>{
        this.$message({
          message:'已取消退出',
          type:'info',
          offset:50,
          customClass:'messageIndex'
        })
      })
    },
  },

}
</script>
<style scoped>
.logo{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  font-size:1.5rem;
  background:#373531;
}
</style>
<style>
.loginout_confirm .el-message-box__content, .loginout_confirm .el-message-box__btns{
  font-size: 1.625rem;
  text-align: center;
}
.loginout_confirm .el-button:nth-child(1){
  background: #FFF;
  width: 7.125rem;
  color: #000;
  font-size: 1.125rem;
  border:1px solid #FFBA56!important;
}
.loginout_confirm .el-button:nth-child(1):hover, .loginout_confirm .el-button:nth-child(1):focus{
  color:#FFBA56;
}
.loginout_confirm .el-button:nth-child(1):active{
  color:#FFBA56;
}
.loginout_confirm .el-button:nth-child(2){
  margin-left: 5.125rem;
  margin-top: 2.625rem;
  width: 7.125rem;
  color: #000;
  font-size: 1.125rem;
  background: #FFBA56;
}
.loginout_confirm .el-button:nth-child(2):hover,.loginout_confirm .el-button:nth-child(2):focus{
  background: #F8B14A;
  color:#000;
}
.loginout_confirm .el-button:nth-child(2):active{
  background: #F8B14A;
  color:#000;
}
.main_color{
  background: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);
}
.control{
  padding:10px;
  padding-bottom: 0;
  background: #e3e3e3;
}
.input-with-select{
  margin-top: 5px;
}
.input-with-select  .el-input-group__prepend{
  background-color: #fff;
}
.login_out{
  color:#FFBE58!important;
  margin:0 20px 0 40px;
}
.white{
  background: white;
}
.app-layout-breadcrumb {
  padding: 15px 20px 15px 20px;
}
.border_bottom{
  border-bottom: solid 1px #e6e6e6;
}
.border_left{
  border-left: 3px solid #409EFF;
  padding-left: 10px;
}
.app-layout-breadcrumb > div {
  font-size: 14px;
  line-height: normal;
}
.details-dialog-css{
  background: rgba(0,0,0,0.5);
}
</style>